import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import slugify from 'slugify';
import Divider from '@material-ui/core/Divider';

import './css/contentful-pages.css';
import PageTitle from '../components/blog-components/page-title';
import SlugLink from '../components/slug-link';
import { getFromStorage } from '../util/storage-utils';
import RichText, { richTextStyles } from '../components/rich-text';
import { BlogArticleFragment } from '../__generated__/gatsby-types';
import colors from '../themes/main-colors';
import ArticleCard from '../components/blog-components/article-card';
import Categories from '../components/blog-components/categories';
import { useTranslation } from '../hooks/useTranslation';

import { usePathContext } from '../providers/PathProvider';
import SocialShare, {
  SocialMediaTypes,
} from '../components/blog-components/social-share';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .RichText': {
        '& .Column': {
          padding: '20px 0',
          '& .WrapperImage picture, .WrapperImage img': {
            marginTop: 0,
          },
        },
      },
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: 5,
      objectFit: 'cover',
    },
    smallHeading: {
      fontSize: theme.spacing(1.75),
      textTransform: 'uppercase',
      fontFamily: 'Forma-DJR-Display',
    },
    paragraph: {
      fontSize: theme.spacing(2.25),
    },
    author: {
      '& a': {
        textDecoration: 'none',
        fontSize: theme.spacing(2.25),
        color: colors.navyBlue,
      },
    },
    tag: {
      fontSize: theme.spacing(2),
      margin: '0.25rem 0.25rem 0.25rem 0',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      fontWeight: 600,
      padding: '0.25rem 1rem',
      verticalAlign: 'middle',
      transition: 'all 250ms ease',
      backgroundColor: colors.lightBlue1,
      color: colors.navyBlue,
      '&:hover': {
        backgroundColor: colors.navyBlue,
        color: colors.lightBlue1,
      },
    },
    divider: {
      margin: '1rem',
      height: 0,
      [theme.breakpoints.down('sm')]: {
        margin: '.5rem',
      },
    },
    stickySection: {
      top: 32,
      position: 'sticky',
    },
  }),
);

export const BlogArticleTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const richText = richTextStyles();
  const { resolve } = usePathContext();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'] || pageContext['es'];
  const [postData] = data.post.nodes;
  const tagCategoryPosts = data.tagCategoryPosts.nodes;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{postData?.browserTitle || postData?.title}</title>
        {postData?.metaDescriptionSeo && (
          <meta name="description" content={postData.metaDescriptionSeo} />
        )}
      </Helmet>
      <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Categories />
      </Container>
      <Container className="component-container">
        <div className={classes.root}>
          <Divider className={classes.divider} />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <PageTitle title={postData.title} postTitle />
              <Divider className={classes.divider} />
              {postData?.heroImage?.file?.url && (
                <img
                  className={classes.image}
                  src={postData?.heroImage?.file?.url}
                />
              )}
              <RichText className={`RichText ${richText.styles}`}>
                {postData?.contentBody}
              </RichText>

              <SocialShare
                platforms={[
                  SocialMediaTypes.Facebook,
                  SocialMediaTypes.Twitter,
                  SocialMediaTypes.Linkedin,
                  SocialMediaTypes.Email,
                ]}
                title={postData?.browserTitle || postData?.title}
                slug={resolve(pageContext.slug)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {postData.articleDate && (
                <>
                  <h3 className={classes.smallHeading}>{t('PUBLISHED')}</h3>
                  <p className={classes.paragraph}>{postData.articleDate}</p>
                </>
              )}
              {postData?.author && (
                <div className={classes.author}>
                  <h3 className={classes.smallHeading}>{t('AUTHOR')}</h3>
                  <SlugLink
                    slug={postData.author.slug}
                    linkText={postData.author.name}
                  />
                </div>
              )}
              {postData?.tags && (
                <>
                  <h3 className={classes.smallHeading}>{t('TOPICS')}</h3>
                  {postData?.tags?.map((tag: string, index: number) => (
                    <SlugLink
                      key={`${tag}${index}`}
                      className={classes.tag}
                      slug={`tag-${slugify(tag, {
                        lower: true,
                        remove: /[^\w\s.{}\/\-]+/g,
                      })}`}
                      linkText={tag}
                    />
                  ))}
                </>
              )}
              {tagCategoryPosts.length > 0 && (
                <div className={classes.stickySection}>
                  <h3 className={classes.smallHeading}>{t('RELATED_POSTS')}</h3>
                  {tagCategoryPosts?.map((relatedPost: BlogArticleFragment) => (
                    <ArticleCard
                      key={relatedPost.id}
                      image={'left'}
                      item={relatedPost}
                      categoryName
                      relatedPost
                    />
                  ))}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};
export default BlogArticleTemplate;

export const query = graphql`
  query PostsPageQuery(
    $slug: String!
    $categoryId: String #$tagsIds: [String] #$hasTags: Boolean!
  ) {
    post: allContentfulArticle(
      filter: { slug: { eq: $slug, regex: "/^((?!placeholder).)*$/i" } }
    ) {
      nodes {
        ...BlogArticle
      }
    }
    # Related posts based on category
    #@include(if: $hasTags)
    tagCategoryPosts: allContentfulArticle(
      filter: {
        category: { id: { eq: $categoryId } }
        #tags: { in: $tagsIds }
        slug: { ne: $slug, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
      limit: 12
    ) {
      nodes {
        ...BlogArticle
      }
    }
  }
`;
