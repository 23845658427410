import React, { FC } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import colors from '../../../../themes/main-colors';

type StyleProps = {
  subHeading: any;
  postTitle: any;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontWeight: ({ subHeading }: StyleProps) =>
        subHeading ? 'bold' : 'normal',
      margin: ({ postTitle }: StyleProps) => (postTitle ? 0 : '20px 0'),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        margin: ({ postTitle }: StyleProps) => (postTitle ? 0 : '0 0 5px 0'),
      },
    },
  }),
);

interface Props {
  title: string;
  style?: any;
  variant?: any;
  postTitle?: any;
  subHeading?: any;
}
const PageTitle: FC<Props> = (props: Props) => {
  const { title, style, variant, postTitle, subHeading } = props;
  const classes = useStyles({ subHeading, postTitle });

  return (
    <>
      <Typography
        variant={variant || postTitle ? 'h1' : 'h2'}
        className={classes.titleText}
        style={style}
        data-testid={'title-text'}
      >
        {title}
      </Typography>
    </>
  );
};

export default PageTitle;
