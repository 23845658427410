import { Box, IconButton } from '@material-ui/core';
import React, { FC } from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useTranslation } from '../../../../hooks/useTranslation';

export enum SocialMediaTypes {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Email = 'email',
}

const generateSocialButton = (
  platform: SocialMediaTypes,
  onClick: () => void,
) => {
  const renderIcon = (type: SocialMediaTypes) => {
    switch (type) {
      case SocialMediaTypes.Facebook:
        return <FacebookIcon />;
      case SocialMediaTypes.Twitter:
        return <TwitterIcon />;
      case SocialMediaTypes.Linkedin:
        return <LinkedInIcon />;
      case SocialMediaTypes.Email:
        return <EmailIcon />;
    }
  };

  return (
    <IconButton
      color={'inherit'}
      size={'small'}
      onClick={onClick}
      key={platform}
      role="button"
      data-testid={`btn-${platform}`}
    >
      {renderIcon(platform)}
    </IconButton>
  );
};

const socialShare = (
  platform: SocialMediaTypes,
  title: string,
  slug: string,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  const encodedTitle = encodeURIComponent(title);
  const SharingURL = window.location.origin
    ? encodeURIComponent(window.location.origin + slug)
    : '';

  const SOCIAL_SHARER_LINKS = {
    [SocialMediaTypes.Facebook]: `https://www.facebook.com/sharer/sharer.php?u=${SharingURL}&quote=${encodedTitle}`,
    [SocialMediaTypes.Twitter]: `https://twitter.com/intent/tweet?url=${SharingURL}&text=${encodedTitle}`,
    [SocialMediaTypes.Linkedin]: `https://www.linkedin.com/shareArticle?url=${SharingURL}`,
    [SocialMediaTypes.Email]: `mailto:?subject=${encodedTitle}&body=${SharingURL}`,
  };

  if (platform === SocialMediaTypes.Email) {
    //We need to close the empty tab if native email client was opened.
    //emailClientWindow?.location.href will be 'about:blank' when the email client opens
    //Accessing emailClientWindow?.location.href will throw CORS error if any other email web client is opened.
    const emailClientWindow = window.open(
      SOCIAL_SHARER_LINKS[platform],
      'noreferrer, noopener',
    );

    setTimeout(() => {
      try {
        if (emailClientWindow?.location.href === 'about:blank') {
          emailClientWindow?.close();
        }
      } catch (Error) {
        //We will receive CORS error if any other web client (gmail, Yahoo etc) was opened.
      }
    }, 3000);

    return;
  }

  window.open(SOCIAL_SHARER_LINKS[platform], '_blank', 'noreferrer, noopener');
};

interface SocialShareProps {
  platforms: SocialMediaTypes[];
  title: string;
  slug: string;
}

const SocialShare: FC<SocialShareProps> = (props: SocialShareProps) => {
  const { t } = useTranslation();

  const { platforms, title, slug } = props;

  return (
    <Box marginTop="1rem">
      {t('SHARE_THIS_BLOG_ON')}

      {platforms.map((socialMediaPlatform: SocialMediaTypes) =>
        generateSocialButton(socialMediaPlatform, () =>
          socialShare(socialMediaPlatform, title, slug),
        ),
      )}
    </Box>
  );
};
export default SocialShare;
